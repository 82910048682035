@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'valorax';
  src: url(./fonts/valorax.otf);
}



body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style:none;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}

.min-h {
  min-height: calc(100vh - 100px - 60px);
}

.background {
  background: linear-gradient(0deg, #161616 0%, #161616 100%), linear-gradient(0deg, #0D091D 0%, #0D091D 100%), linear-gradient(163deg, #070714 3.21%, #19062E 52.11%);
}

.nav-text:hover, .nav-text.active {
  background: linear-gradient(104deg, #EBCF90 -11.95%, #E35A18 120.77%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0;
  background: none !important;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-content {
  background-color: #161616 !important;
}