.connect-button {
    position: relative;
}

.connect-button::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 14px; 
    padding: 2px; 
    background:linear-gradient(to right, #FFFFFF, #EEB732, #E94410); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 

}

.ant-dropdown-menu-root {
    margin-top: 6px !important;
    border: 1px solid #FFB800 !important;
    border-radius: 12px !important;
    background-color: #161616 !important;
    padding: 12px 8px !important;
    overflow-y: auto;
    max-height: 300px;
  
  }