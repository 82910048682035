.layer :where(.css-1kuana8).ant-divider-horizontal {
    width: 200px !important;
    min-width: 0 !important;
}

.layer :where(.css-dev-only-do-not-override-1kuana8).ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
    width: 200px !important;
    min-width: 0 !important;
    border-width: 2px !important;
}

.wrapper {
    transform: translate(-50%, -50%);
}

.first {
    height: 200px;
    width: 200px;
    background-color: #FFB800;
    border-radius: 40px;
    transform-style: preserve-3d;
    z-index: 5;
    transform: rotate(-60deg) skewY(25deg);
    filter: drop-shadow(-4px 46px 20px rgba(0, 0, 0, 0.12));
    animation: up 4000ms ease-out infinite backwards;
}
.first-small {
    height: 150px;
    width: 150px;
    background-color: #FFB800;
    border-radius: 25px;
    transform-style: preserve-3d;
    z-index: 5;
    transform: rotate(-60deg) skewY(25deg);
    filter: drop-shadow(-4px 46px 20px rgba(0, 0, 0, 0.12));
    animation: up-small 4000ms ease-out infinite backwards;
}
.second {
    height: 160px;
    width: 160px;
    background-color: #C3922A;
    border-radius: 25px;
    transform-style: preserve-3d;
    z-index: 4;
    transform: rotate(-60deg) skewY(25deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}
.second-small {
    height: 120px;
    width: 120px;
    background-color: #C3922A;
    border-radius: 20px;
    transform-style: preserve-3d;
    z-index: 4;
    transform: rotate(-60deg) skewY(25deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.third {
    height: 170px;
    width: 170px;
    background-color: #C3922A;
    opacity: 0.5;
    border-radius: 25px;
    transform-style: preserve-3d;
    z-index: 3;
    transform: rotate(-60deg) skewY(25deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.third-small {
    height: 130px;
    width: 130px;
    background-color: #C3922A;
    opacity: 0.5;
    border-radius: 20px;
    transform-style: preserve-3d;
    z-index: 3;
    transform: rotate(-60deg) skewY(25deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.fourth {
    height: 180px;
    width: 180px;
    background-color: rgba(195, 146, 42, 0.50);
    stroke-width: 0.2px;
    stroke: #9D9494;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 25px;
    transform-style: preserve-3d;
    z-index: 2;
    transform: rotate(-60deg) skewY(25deg);
}
.fourth-small {
    height: 140px;
    width: 140px;
    background-color: rgba(195, 146, 42, 0.50);
    stroke-width: 0.2px;
    stroke: #9D9494;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    transform-style: preserve-3d;
    z-index: 2;
    transform: rotate(-60deg) skewY(25deg);
}
.fifth {
    height: 190px;
    width: 190px;
    background-color: rgba(195, 146, 42, 0.30);
    stroke-width: 0.5px;
    stroke: #595959;
    border-radius: 25px;
    transform-style: preserve-3d;
    z-index: 1;
    transform: rotate(-60deg) skewY(25deg);
    border: 1px solid #C3922A;
}

.fifth-small {
    height: 150px;
    width: 150px;
    background-color: rgba(195, 146, 42, 0.30);
    stroke-width: 0.5px;
    stroke: #595959;
    border-radius: 20px;
    transform-style: preserve-3d;
    z-index: 1;
    transform: rotate(-60deg) skewY(25deg);
    border: 1px solid #C3922A;
}

.to-first {
    opacity: 0;
    animation: to-first 4000ms ease-out infinite backwards;
}
.to-first-small {
    opacity: 0;
    animation: to-first-small 4000ms ease-out infinite backwards;
}

@keyframes up {
    0% {
      transform: translateY(0) rotate(-60deg) skewY(25deg);
      opacity: 1;
    }
  
    50% {
      transform: translateY(-110%) rotate(-60deg) skewY(25deg);
      opacity: 1;
    }

    100% {
      transform: translateY(-110%) rotate(-60deg) skewY(25deg);
      opacity: 1;
    }
  }
@keyframes up-small {
    0% {
      transform: translateY(0) rotate(-60deg) skewY(25deg);
      opacity: 1;
    }
  
    50% {
      transform: translateY(-110%) rotate(-60deg) skewY(25deg);
      opacity: 1;
    }

    100% {
      transform: translateY(-110%) rotate(-60deg) skewY(25deg);
      opacity: 1;
    }
  }

@keyframes to-first {
    0% {
        height: 160px;
        width: 160px;
        background-color: #C3922A;
        border-radius: 25px;
        opacity: 0.5;
        z-index: 5;
        transform: translateY(0) rotate(-60deg) skewY(25deg);
    }
  
    50% {
        height: 200px;
        width: 200px;
        background-color: #FFB800;
        border-radius: 40px;
        opacity: 1;
        z-index: 5;
        filter: drop-shadow(-4px 46px 20px rgba(0, 0, 0, 0.12));
        transform: translateY(-100px) translateX(-20px) rotate(-60deg) skewY(25deg);
    }
    100% {
        height: 200px;
        width: 200px;
        background-color: #FFB800;
        border-radius: 40px;
        opacity: 1;
        z-index: 5;
        filter: drop-shadow(-4px 46px 20px rgba(0, 0, 0, 0.12));
        transform: translateY(-100px) translateX(-20px) rotate(-60deg) skewY(25deg);
    }
  }

@keyframes to-first-small {
    0% {
        height: 120px;
        width: 120px;
        background-color: #C3922A;
        border-radius: 20px;
        opacity: 0.5;
        z-index: 5;
        transform: translateY(0) rotate(-60deg) skewY(25deg);
    }
  
    50% {
        height: 150px;
        width: 150px;
        background-color: #FFB800;
        border-radius: 25px;
        opacity: 1;
        z-index: 5;
        filter: drop-shadow(-4px 46px 20px rgba(0, 0, 0, 0.12));
        transform: translateY(-80px) translateX(-20px) rotate(-60deg) skewY(25deg);
    }
    100% {
        height: 150px;
        width: 150px;
        background-color: #FFB800;
        border-radius: 25px;
        opacity: 1;
        z-index: 5;
        filter: drop-shadow(-4px 46px 20px rgba(0, 0, 0, 0.12));
        transform: translateY(-80px) translateX(-20px) rotate(-60deg) skewY(25deg);
    }
  }