  
  section {
    gap: 60px;
    display: flex;
    align-items: center;
  }

  .slides-wrap:hover section {
    animation-play-state: paused !important;
  }
  
  @keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }