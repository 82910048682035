.connect-button::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 18px; 
    padding: 2px; 
    background:linear-gradient(to right, #FFFFFF, #EEB732, #E94410); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 

}

.nav-text:hover, .nav-text.active {
    background: linear-gradient(104deg, #EBCF90 -11.95%, #E35A18 120.77%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }