.box::after {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    background: transparent;
    border-bottom-left-radius: 23px;
    box-shadow: -1px 1px 0 0 rgba(92,34,4,0.60), -5px 5px 0 5px #161616;
    right: -24px;
    bottom: 0;
}
.box-2::after {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    background: transparent;
    border-bottom-right-radius: 23px;
    box-shadow: 1px 1px 0 0 rgba(92,34,4,0.60), 5px 5px 0 5px #161616;
    left: -24px;
    bottom: 0;
}

.box, .box-2 {
    box-shadow: 0 1px 0 0 #161616;
}